<style scoped>
.retry-after-wrapper {
  border-radius: 5px;
}
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
/* .tab-status-underline::after{
  content: '';
  position: absolute;
  width: 25%;
  height: 5px;
  left: 0;
  top: 49px;
  background: red;
} */
.bank-statment-rows {
  border-bottom: 1px solid #0000001a;
}
.hover-bg:hover {
  background-color: #00000016;
}
</style>
<template>
  <div>
    <DivLoader
      v-if="loader"
      :loading_type="
        'image, list-item-two-line, image, list-item-two-line, image, list-item-two-line, image, list-item-two-line, image, list-item-two-line'
      "
    ></DivLoader>
    <!-- 'list-item-two-line, list-item-two-line, image, table-tfoot' -->
    <div v-else>
      <div class="text-h6 font-weight-bold mb-5 d-flex">
        <p>Basic Details</p>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          class="primary_2--text mr-4"
          @click="toggleAllAccordion()"
        >
          <v-tooltip left v-if="!are_all_collapsed">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary_2" v-bind="attrs" v-on="on">
                mdi-arrow-collapse-vertical
              </v-icon>
            </template>
            <span>Collapse all</span>
          </v-tooltip>
          <v-tooltip left v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary_2" v-bind="attrs" v-on="on">
                mdi-arrow-expand-vertical
              </v-icon>
            </template>
            <span>Expand all</span>
          </v-tooltip>
        </v-btn>
      </div>
      <v-card elevation="5">
        <v-card-text class="pa-0">
          <!-- tabs for status -->
          <div
            class="primary_2--text text-body-1 font-weight-medium text-center"
          >
            <v-row class="pb-3 pt-1">
              <v-col cols="3" @click="viewOnlyEligiblity()">
                Check Eligiblity
                <span
                  v-if="
                    is_check_eligibility_approved &&
                      check_eligibility_details.pan_number
                  "
                >
                  <v-icon color="green">
                    mdi-check-circle-outline
                  </v-icon>
                </span>
                <span v-else-if="!is_check_eligibility_approved">
                  <template v-if="check_eligibility_block_data.blocked_reason">
                    <template
                      v-if="
                        check_eligibility_block_data.blocked_reason[0]
                          .rejection_reason != 'KYC'
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="red" v-bind="attrs" v-on="on">
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          <ul
                            v-for="item in check_eligibility_block_data.blocked_reason"
                            :key="item.rejection_reason"
                          >
                            <li>{{ item.rejection_reason }}</li>
                          </ul>
                        </span>
                      </v-tooltip>
                      <div>
                        <span class="warning pa-1 ml-1 retry-after-wrapper">
                          <v-icon color="primary_2" size="20">
                            mdi-reload </v-icon
                          ><span class="font-weight-bold ml-1">
                            {{ check_eligibility_block_data.blocked_till }}
                          </span>
                        </span>
                      </div>
                    </template>
                  </template>
                </span>
              </v-col>
              <v-col
                @click="viewOnlyApplication()"
                cols="3"
                class="tab-status-underline"
              >
                Application Form
                <v-icon v-if="is_application_form_approved" color="green">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon
                  v-else-if="
                    !is_application_form_approved &&
                      current_address_details.area
                  "
                  color="secondary_2"
                >
                  mdi-alert-circle-outline
                </v-icon>
                <!-- <span class="warning pa-1 retry-after-wrapper">
                <v-icon color="primary_2" size="20">
                    mdi-reload
                </v-icon><span class="font-weight-bold ml-1">15</span>
                </span> -->
              </v-col>
              <v-col cols="3" @click="viewOnlyKyc()">
                KYC
                <v-icon v-if="is_kyc_approved" color="green">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon v-else-if="is_action_requried_kyc" color="secondary_2">
                  mdi-alert-circle-outline
                </v-icon>
                <span
                  v-else-if="!is_kyc_approved && kyc_details.block_days_left"
                >
                  <v-icon v-if="kyc_details.block_days_left" color="red">
                    mdi-alert-circle-outline
                  </v-icon>
                  <span
                    v-if="kyc_details.block_days_left"
                    class="warning pa-1 retry-after-wrapper"
                  >
                    <v-icon color="primary_2" size="20">
                      mdi-reload
                    </v-icon>
                    <span class="font-weight-bold ml-1">
                      {{ kyc_details.block_days_left }}
                    </span>
                  </span>
                </span>
                <!-- <div class="d-flex">
                <v-icon class="mx-1" color="white">
                  mdi-alert-octagon-outline
                </v-icon>
                <div
                  class="warning black--text px-3 mx-1 retry-after-wrapper"
                >
                  <span class="text-caption">Retry After - </span
                  >{{ kyc_block_days_left }} days
                </div>
              </div> -->
              </v-col>
              <v-col cols="3" @click="viewOnlyBank()">
                Bank Details
                <v-icon v-if="is_bank_details_approved" color="green">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon
                  v-else-if="!is_bank_details_approved && bank_statements"
                  color="secondary_2"
                >
                  mdi-alert-circle-outline
                </v-icon>
                <!-- <v-icon color="green">
                    mdi-check-circle-outline
                </v-icon>
                <v-icon color="red">
                    mdi-alert-circle-outline
                </v-icon> -->
                <!-- <span class="warning pa-1 retry-after-wrapper">
                <v-icon color="primary_2" size="20"> mdi-reload </v-icon
                ><span class="font-weight-bold ml-1">15</span>
              </span> -->
              </v-col>
            </v-row>
          </div>

          <!-- accordion headers -->
          <div v-for="item in accordionHeaders" :key="item.id">
            <div
              v-if="item.title == 'Check Eligibility'"
              class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
            >
              <div
                style="width: 75%"
                class="d-flex align-center py-3"
                @click="toggleAccordion(item)"
              >
                <div class="text-body-1 font-weight-medium px-3">
                  {{ item.title }}
                </div>
                <div v-if="!is_check_eligibility_approved" class="d-flex">
                  <template v-if="check_eligibility_block_data.blocked_reason">
                    <template
                      v-if="
                        check_eligibility_block_data.blocked_reason[0]
                          .rejection_reason != 'KYC'
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="white" v-bind="attrs" v-on="on">
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          <ul
                            v-for="item in check_eligibility_block_data.blocked_reason"
                            :key="item.rejection_reason"
                          >
                            <li>{{ item.rejection_reason }}</li>
                          </ul>
                        </span>
                      </v-tooltip>
                      <div
                        class="warning black--text px-3 mx-1 retry-after-wrapper"
                      >
                        <span class="text-caption">Retry After - </span>
                        {{ check_eligibility_block_data.blocked_till }} days
                      </div>
                    </template>
                  </template>
                </div>
              </div>
              <div style="width: 25%" class="d-flex align-center justify-end">
                <v-btn
                  small
                  color="secondary_2"
                  class="font-weight-bold mr-3 white--text"
                  @click="openEditEligibilityCheck()"
                  :disabled="check_eligibility_details.is_update_locked"
                >
                  Edit
                </v-btn>
                <v-icon
                  color="white"
                  v-if="item.show"
                  @click="toggleAccordion(item)"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else @click="toggleAccordion(item)">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>
            <!-- 
          <div
            v-else-if="item.title == 'Application Form'"
            class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
          >
            <div
              style="width: 75%"
              class="d-flex align-center py-3"
              @click="toggleAccordion(item)"
            >
              <div class="text-body-1 font-weight-medium px-3">
                {{ item.title }}
              </div>
              <div v-if="!is_check_eligibility_approved" class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="white" v-bind="attrs" v-on="on">
                      mdi-alert-octagon-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ check_eligibility_block_data.blocked_reason }}
                  </span>
                </v-tooltip>
                <div class="warning black--text px-3 mx-1 retry-after-wrapper">
                  <span class="text-caption">Retry After - </span>
                  {{ check_eligibility_block_data.blocked_till }} days
                </div>
              </div>
            </div>
          </div> -->

            <div
              v-else-if="item.title == 'Application Form'"
              class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
            >
              <div
                style="width: 75%"
                class="d-flex align-center py-3"
                @click="toggleAccordion(item)"
              >
                <div class="text-body-1 font-weight-medium px-3">
                  {{ item.title }}
                </div>
                <v-icon
                  v-if="
                    !is_application_form_approved &&
                      current_address_details.area
                  "
                  color="white"
                >
                  mdi-alert-circle-outline
                </v-icon>
                <!-- <div v-if="!is_application_form_approved" class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="white" v-bind="attrs" v-on="on">
                      mdi-alert-octagon-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ application_form_block_data.blocked_reason }}
                  </span>
                </v-tooltip>
                <div
                  v-if="application_form_block_data.blocked_till"
                  class="warning black--text px-3 mx-1 retry-after-wrapper"
                >
                  <span class="text-caption">Retry After - </span
                  >{{ application_form_block_data.blocked_till }} days
                </div>
              </div> -->
              </div>
              <div style="width: 25%" class="d-flex align-center justify-end">
                <v-icon
                  color="white"
                  v-if="item.show"
                  @click="toggleAccordion(item)"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else @click="toggleAccordion(item)">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>

            <div
              v-else-if="item.title == 'CIBIL Report'"
              class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
            >
              <div
                style="width: 75%"
                class="d-flex align-center py-3"
                @click="toggleAccordion(item)"
              >
                <div class="text-body-1 font-weight-medium px-3">
                  {{ item.title }}
                </div>
                <!-- <v-icon
                  v-if="
                    !is_application_form_approved &&
                      current_address_details.area
                  "
                  color="white"
                >
                  mdi-alert-circle-outline
                </v-icon> -->
              </div>
              <div style="width: 25%" class="d-flex align-center justify-end">
                <v-icon
                  color="white"
                  v-if="item.show"
                  @click="toggleAccordion(item)"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else @click="toggleAccordion(item)">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>

            <div
              v-else-if="item.title == 'KYC'"
              class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
            >
              <div
                style="width: 75%"
                class="d-flex align-center py-3"
                @click="toggleAccordion(item)"
              >
                <div class="text-body-1 font-weight-medium px-3">
                  {{ item.title }}
                </div>
                <div
                  v-if="!is_kyc_approved && kyc_extra_details.selfie_url"
                  class="d-flex"
                >
                  <v-icon class="mx-1" color="white">
                    mdi-alert-circle-outline
                  </v-icon>
                  <div
                    v-if="kyc_details.block_days_left"
                    class="warning black--text px-3 mx-1 retry-after-wrapper"
                  >
                    <span class="text-caption">Retry After - </span
                    >{{ kyc_details.block_days_left }} days
                  </div>
                </div>
              </div>
              <div style="width: 25%" class="d-flex align-center justify-end">
                <!-- new btn added to view all documents -->
                <v-btn
                  dense
                  color="secondary_2"
                  class="mr-3 font-weight-bold white--text"
                  v-if="getPermission('View All Documents')"
                  small
                  @click="sendEventToDocumentsDialog"
                  >View all docs</v-btn
                >
                <!-- new btn added to view all documents -->
                <v-btn
                  small
                  dark
                  class="font-weight-bold mr-3"
                  color="red"
                  :loading="reset_kyc_btn_loader"
                  :disabled="reset_kyc_btn_loader"
                  @click="
                    openConfirmationDialog(
                      'Confirmation',
                      'Are you sure you want to reset KYC?'
                    )
                  "
                >
                  <!-- v-if="
                    kyc_details.pan_status &&
                      kyc_dqetails.pan_status != 'Success'
                  " -->
                  Reset
                </v-btn>
                <v-icon
                  color="white"
                  v-if="item.show"
                  @click="toggleAccordion(item)"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else @click="toggleAccordion(item)">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>

            <div
              v-else-if="item.title == 'Bank Details'"
              class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
            >
              <div
                style="width: 75%"
                class="d-flex align-center py-3"
                @click="toggleAccordion(item)"
              >
                <div class="text-body-1 font-weight-medium px-3">
                  {{ item.title }}
                </div>
                <!-- <v-icon v-if="is_bank_details_approved" color="green">
                mdi-check-circle-outline
              </v-icon> -->
                <v-icon
                  v-if="!is_bank_details_approved && bank_statements"
                  color="white"
                >
                  mdi-alert-circle-outline
                </v-icon>
                <!-- <v-icon class="mx-1" color="white">
                mdi-alert-octagon-outline
              </v-icon>
              <div class="warning black--text px-3 mx-1 retry-after-wrapper">
                <span class="text-caption">Retry After - </span>15 days
              </div> -->
              </div>
              <div style="width: 25%" class="d-flex align-center justify-end">
                <!-- <v-btn
                color="success"
                class="font-weight-bold"
                dark
                small
                @click="pass()"
              >
                Pass
              </v-btn> -->
                <v-btn
                  color="secondary_2"
                  dark
                  small
                  class="mx-2 font-weight-bold"
                  @click="openUploadBankStatementDialog()"
                >
                  <!-- :disabled="!bank_details.account_number" -->
                  Upload
                </v-btn>
                <v-icon
                  color="white"
                  v-if="item.show"
                  @click="toggleAccordion(item)"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else @click="toggleAccordion(item)">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>

            <div
              v-else
              class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
            >
              <div
                style="width: 75%"
                class="d-flex align-center py-3"
                @click="toggleAccordion(item)"
              >
                <div class="text-body-1 font-weight-medium px-3">
                  {{ item.title }}
                </div>
              </div>
              <div style="width: 25%" class="d-flex align-center justify-end">
                <v-icon
                  color="white"
                  v-if="item.show"
                  @click="toggleAccordion(item)"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else @click="toggleAccordion(item)">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>

            <!-- accordion content -->
            <div>
              <div
                v-if="item.title == 'Check Eligibility' && item.show"
                class="pa-5"
              >
                <v-row>
                  <v-col cols="4">
                    <p class="field-title">
                      Customer Name
                    </p>
                    <p
                      v-if="check_eligibility_details.name"
                      class="field-content"
                    >
                      {{ check_eligibility_details.name }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Father's Name
                    </p>
                    <p
                      v-if="check_eligibility_details.father_name"
                      class="field-content"
                    >
                      {{ check_eligibility_details.father_name }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      DOB
                    </p>
                    <p
                      v-if="check_eligibility_details.date_of_birth"
                      class="field-content"
                      :class="is_dob_valid ? '' : 'error--text'"
                    >
                      {{ check_eligibility_details.date_of_birth }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-tooltip
                      max-width="220"
                      color="blue-grey lighten-5 pa-3"
                      bottom
                      v-if="check_eligibility_details.is_pincode_changed"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p class="field-title" v-bind="attrs" v-on="on">
                          Pincode (Current Address)
                          <v-icon color="red">mdi-alert-circle-outline</v-icon>
                        </p>
                      </template>
                      <p class="black--text">
                        Pincode has been changed from
                        <span class="font-weight-black"
                          >"{{ check_eligibility_details.old_pin_code }}"</span
                        >
                        to
                        <span class="font-weight-black"
                          >"{{ check_eligibility_details.pincode }}"</span
                        >"
                      </p>
                    </v-tooltip>
                    <p v-else class="field-title">
                      Pincode (Current Address)
                    </p>
                    <p
                      v-if="check_eligibility_details.pincode"
                      class="field-content"
                      :class="is_picode_valid ? '' : 'error--text'"
                    >
                      {{ check_eligibility_details.pincode }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Employment Type
                    </p>
                    <p
                      v-if="check_eligibility_details.employment_type"
                      class="field-content"
                      :class="is_employment_type_valid ? '' : 'error--text'"
                    >
                      {{ check_eligibility_details.employment_type }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Salary Type
                    </p>
                    <p
                      v-if="check_eligibility_details.salary_type"
                      class="field-content"
                      :class="is_salary_type_valid ? '' : 'error--text'"
                    >
                      {{ check_eligibility_details.salary_type }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <p class="field-title">
                      Net Monthly Salary
                    </p>
                    <p
                      v-if="check_eligibility_details.net_monthly_income"
                      class="field-content"
                      :class="is_monthly_salary_valid ? '' : 'error--text'"
                    >
                      ₹{{ check_eligibility_details.net_monthly_income }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      PAN No.
                    </p>
                    <p
                      v-if="check_eligibility_details.pan_number"
                      class="field-content"
                    >
                      {{ check_eligibility_details.pan_number }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Recent CIBIL Score
                    </p>
                    <p
                      v-if="check_eligibility_details.recent_cibil_score"
                      class="field-content"
                    >
                      {{ check_eligibility_details.recent_cibil_score }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      New Loan in Last Six Month
                    </p>
                    <p
                      v-if="
                        check_eligibility_details.new_loan_in_last_six_month
                      "
                      class="field-content"
                    >
                      {{ check_eligibility_details.new_loan_in_last_six_month }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Bounces in Last Three Month
                    </p>
                    <p
                      v-if="
                        check_eligibility_details.bounces_in_last_three_month
                      "
                      class="field-content"
                    >
                      {{
                        check_eligibility_details.bounces_in_last_three_month
                      }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Employer Pincode
                    </p>
                    <p
                      v-if="check_eligibility_details.employer_pin_code"
                      class="field-content"
                    >
                      {{ check_eligibility_details.employer_pin_code }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      New Loan in last six Month Amount
                    </p>
                    <p
                      v-if="
                        check_eligibility_details.new_loan_in_last_six_month_amount
                      "
                      class="field-content"
                    >
                      {{
                        check_eligibility_details.new_loan_in_last_six_month_amount
                      }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Number of Bounces in Last Three Month
                    </p>
                    <p
                      v-if="
                        check_eligibility_details.number_of_bounces_in_last_three_month
                      "
                      class="field-content"
                    >
                      {{
                        check_eligibility_details.number_of_bounces_in_last_three_month
                      }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Loan Enquiry
                    </p>
                    <p
                      class="field-content"
                      v-for="(item,
                      index) in check_eligibility_details.loan_enquiry"
                      :key="index"
                    >
                      {{ item }}
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Platform
                    </p>
                    <p
                      v-if="check_eligibility_details.platform"
                      class="field-content"
                    >
                      {{ check_eligibility_details.platform }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Campaign Name
                    </p>
                    <p
                      v-if="check_eligibility_details.campaign_name"
                      class="field-content"
                    >
                      {{ check_eligibility_details.campaign_name }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      UTM Medium
                    </p>
                    <p
                      v-if="check_eligibility_details.utm_medium"
                      class="field-content"
                    >
                      {{ check_eligibility_details.utm_medium }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
              </div>
              <div
                v-if="item.title == 'Application Form' && item.show"
                class="px-3 pt-2"
              >
                <v-row
                  class="blue-grey lighten-4 text-body-1 font-weight-medium pl-3"
                >
                  <v-col>
                    Employment Details
                  </v-col>
                </v-row>
                <v-row class="pa-3" v-if="employment_details">
                  <v-col cols="4" class="pb-0">
                    <p class="field-title">
                      Company Name
                    </p>
                    <p
                      v-if="employment_details.employer_name != ''"
                      class="field-content"
                    >
                      {{ employment_details.employer_name }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4" class="pb-0">
                    <p class="field-title">
                      Designation
                    </p>
                    <p
                      v-if="employment_details.designation"
                      class="field-content"
                    >
                      {{ employment_details.designation }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4" class="pb-0">
                    <p class="field-title">
                      Total Work Experience
                    </p>
                    <p class="field-content">
                      {{ employment_details.experience_in_year }} Year(s),
                      {{ employment_details.experience_in_month }} Month(s)
                    </p>
                    <!-- <p v-else class="field-content">
                      -
                    </p> -->
                  </v-col>
                </v-row>
                <v-row class="pa-3">
                  <v-col cols="4" class="pt-0">
                    <p class="field-title">
                      Pincode
                    </p>
                    <p
                      v-if="employment_details.employer_pin_code"
                      class="field-content"
                    >
                      {{ employment_details.employer_pin_code }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4" class="pt-0">
                    <p class="field-title">
                      City
                    </p>
                    <p
                      v-if="employment_details.employer_city"
                      class="field-content"
                    >
                      {{ employment_details.employer_city }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4" class="pt-0">
                    <p class="field-title">
                      State
                    </p>
                    <p
                      v-if="employment_details.employer_state"
                      class="field-content"
                    >
                      {{ employment_details.employer_state }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
                <v-row class="pa-3">
                  <v-col cols="4" class="pt-0">
                    <p class="field-title">
                      Offical Email Address
                    </p>
                    <p
                      v-if="employment_details.official_email"
                      class="field-content"
                    >
                      {{ employment_details.official_email }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="8" class="pt-0">
                    <p class="field-title">
                      Complete Company Address
                    </p>
                    <p
                      v-if="employment_details.employer_address"
                      class="field-content"
                    >
                      {{ employment_details.employer_address }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  class="blue-grey lighten-4 text-body-1 font-weight-medium pl-3"
                >
                  <v-col>
                    General Information
                  </v-col>
                </v-row>
                <v-row class="pa-3">
                  <v-col cols="4" class="pb-0">
                    <p class="field-title">
                      Gender
                    </p>
                    <p v-if="general_details.gender" class="field-content">
                      {{ general_details.gender }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4" class="pb-0">
                    <p class="field-title">
                      Education
                    </p>
                    <p v-if="general_details.education" class="field-content">
                      {{ general_details.education }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4" class="pb-0">
                    <p class="field-title">
                      Institute
                    </p>
                    <p
                      v-if="general_details.institute_name"
                      class="field-content"
                    >
                      {{ general_details.institute_name }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
                <v-row class="pa-3 pb-4">
                  <v-col cols="4" class="pb-0">
                    <p class="field-title">
                      Maritial Status
                    </p>
                    <p
                      v-if="general_details.marital_status"
                      class="field-content"
                    >
                      {{ general_details.marital_status }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4" class="pt-0">
                    <p class="field-title">
                      Family Reference Contact
                    </p>
                    <div
                      v-if="general_details.family_reference"
                      class="field-content"
                    >
                      <p>{{ general_details.family_reference.relation }}</p>
                      <p>{{ general_details.family_reference.name }}</p>
                      <p>
                        {{ general_details.family_reference.contact_number }}
                      </p>
                    </div>
                    <div v-else class="field-content">
                      -
                    </div>
                  </v-col>
                  <v-col cols="4" class="pt-0">
                    <p class="field-title">
                      Friend Reference Contact
                    </p>
                    <div
                      v-if="general_details.friend_reference"
                      class="field-content"
                    >
                      <p>{{ general_details.friend_reference.relation }}</p>
                      <p>{{ general_details.friend_reference.name }}</p>
                      <p>
                        {{ general_details.friend_reference.contact_number }}
                      </p>
                    </div>
                    <div v-else class="field-content">
                      -
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  class="blue-grey lighten-4 text-body-1 font-weight-medium pl-3"
                >
                  <v-col cols="6">
                    Address Details
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      color="secondary_2"
                      dark
                      small
                      class="mx-2 font-weight-bold"
                      @click="openUploadAddressProofDialog()"
                    >
                      Upload
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  class="pl-3 pt-2 text-body-1 font-weight-bold primary_2--text"
                >
                  <v-col class="pb-0">
                    CURRENT ADDRESS
                  </v-col>
                </v-row>
                <v-row class="pa-3">
                  <v-col cols="4" class="pb-0">
                    <p class="field-title">
                      Current Resident Type
                    </p>
                    <p
                      v-if="current_address_details.residential_type"
                      class="field-content"
                    >
                      {{ current_address_details.residential_type }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col class="pb-0">
                    <p class="field-title">
                      Current Complete Address
                    </p>
                    <p
                      v-if="current_address_details.home_number"
                      class="field-content"
                    >
                      {{ current_address_details.home_number }},
                      {{ current_address_details.area }},
                      {{ current_address_details.city }},
                      {{ current_address_details.state }},
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
                <v-row class="pa-3 pb-0">
                  <v-col cols="4" class="pt-0">
                    <v-tooltip
                      max-width="220"
                      color="blue-grey lighten-5 pa-3"
                      bottom
                      v-if="current_address_details.is_pincode_changed"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p class="field-title" v-bind="attrs" v-on="on">
                          Pincode (Current Address)
                          <v-icon color="red">mdi-alert-circle-outline</v-icon>
                        </p>
                      </template>
                      <p class="black--text">
                        Pincode has been changed from
                        <span class="font-weight-black"
                          >"{{ current_address_details.old_pin_code }}"</span
                        >
                        to
                        <span class="font-weight-black"
                          >"{{ current_address_details.pin_code }}"</span
                        >"
                      </p>
                    </v-tooltip>
                    <p v-else class="field-title">
                      Current Pincode
                    </p>
                    <!-- <p class="field-title">
                    </p> -->
                    <p
                      v-if="current_address_details.pin_code"
                      class="field-content"
                    >
                      {{ current_address_details.pin_code }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4" class="pt-0">
                    <p class="field-title">
                      Address Proof
                    </p>
                    <p
                      v-if="
                        current_address_details.aadhaar_address_same_as_current_address
                      "
                      class="field-content"
                    >
                      Aadhaar Card
                    </p>
                    <p v-else-if="address_proof_type" class="field-content">
                      {{ address_proof_type }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  class="px-3 pb-2 hover-bg bank-statment-rows"
                  v-for="item in address_proof"
                  :key="item.id"
                >
                  <v-col cols="4">
                    <p class="field-title">
                      Address Proof Document
                    </p>
                    <p class="field-content">
                      {{
                        item.document_type
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (s) => s.charAt(0).toUpperCase() + s.substring(1)
                          )
                          .join(" ")
                      }}
                    </p>
                    <p class="error--text text-caption">
                      {{ item.rejection_reason }}
                    </p>
                  </v-col>
                  <v-col cols="3" class="d-flex align-center">
                    <!-- {{ item.document_status }} -->
                    <span
                      class="text-body-2 ml-2 font-weight-bold success--text"
                      v-if="item.document_status == 'approved'"
                    >
                      <v-icon class="success--text mr-1">
                        mdi-check-circle-outline </v-icon
                      >Approved
                    </span>
                    <span
                      class="text-body-2 ml-2 font-weight-bold secondary_2--text"
                      v-else-if="item.document_status == 'requested'"
                      ><v-icon class="secondary_2--text mr-1">
                        mdi-alert-circle-outline </v-icon
                      >Requested
                    </span>
                    <span
                      v-else-if="item.document_status == 'rejected'"
                      class="text-body-2 ml-2 font-weight-bold error--text"
                    >
                      <v-icon class="error--text mr-1">
                        mdi-close-circle-outline</v-icon
                      >Rejected
                    </span>
                    <span v-else> </span>
                  </v-col>
                  <v-col cols="5" class="d-flex align-center justify-end pr-8">
                    <v-btn
                      small
                      text
                      @click="
                        isImageDocument(item.document)
                          ? previewImage(item.document, item.document_type)
                          : viewDocument(item.document)
                      "
                      class="indigo lighten-5 py-4"
                    >
                      <v-icon color="deep-purple darken-4"
                        >mdi-eye-outline</v-icon
                      >
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  class="pl-3 pt-0 text-body-1 font-weight-bold primary_2--text"
                >
                  <v-col class="pb-0">
                    PERMANENT ADDRESS
                  </v-col>
                </v-row>
                <!-- <v-row class="pa-3"> </v-row> -->
                <v-row class="pa-3">
                  <v-col cols="4" class="pb-0">
                    <p class="field-title">
                      Permanent Resident Type
                    </p>
                    <p
                      v-if="permanent_address_details.residential_type"
                      class="field-content"
                    >
                      {{ permanent_address_details.residential_type }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col class="pb-0">
                    <p class="field-title">
                      Permanent Complete Address
                    </p>
                    <p
                      v-if="permanent_address_details.home_number"
                      class="field-content"
                    >
                      {{ permanent_address_details.home_number }},
                      {{ permanent_address_details.area }},
                      {{ permanent_address_details.city }},
                      {{ permanent_address_details.state }},
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
                <v-row class="pa-3 pb-5">
                  <v-col cols="4" class="pt-0">
                    <v-tooltip
                      max-width="220"
                      color="blue-grey lighten-5 pa-3"
                      bottom
                      v-if="permanent_address_details.is_pincode_changed"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p class="field-title" v-bind="attrs" v-on="on">
                          Pincode (Current Address)
                          <v-icon color="red">mdi-alert-circle-outline</v-icon>
                        </p>
                      </template>
                      <p class="black--text">
                        Pincode has been changed from
                        <span class="font-weight-black"
                          >"{{ permanent_address_details.old_pin_code }}"</span
                        >
                        to
                        <span class="font-weight-black"
                          >"{{ permanent_address_details.pin_code }}"</span
                        >"
                      </p>
                    </v-tooltip>
                    <p v-else class="field-title">
                      Permanent Pincode
                    </p>
                    <!-- <p class="field-title">
                    </p> -->
                    <p
                      v-if="permanent_address_details.pin_code"
                      class="field-content"
                    >
                      {{ permanent_address_details.pin_code }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
              </div>
              <div
                v-if="item.title == 'CIBIL Report' && item.show"
                class="px-3 pt-2"
              >
                <CibilSection></CibilSection>
              </div>
              <div v-if="item.title == 'KYC' && item.show">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex align-center justify-space-between pb-0 px-10"
                  >
                    <v-col cols="4">
                      <p class="field-title  d-flex align-center ">
                        Aadhaar Card
                      </p>
                      <p
                        v-if="aadhaar_details.aadhaar_uid"
                        class="field-content"
                      >
                        {{ aadhaar_details.aadhaar_uid }}
                      </p>
                      <p v-else class="field-content">
                        -
                      </p>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-center">
                      <span
                        class="text-body-2 ml-2 font-weight-bold success--text"
                        v-if="kyc_details.aadhar_status == 'Success'"
                      >
                        <v-icon class="success--text mr-1">
                          mdi-check-circle-outline </v-icon
                        >Success
                      </span>
                      <span
                        class="text-body-2 ml-2 font-weight-bold blue--text"
                        v-else-if="kyc_details.aadhar_status == 'Under Review'"
                      >
                        Under Review
                      </span>
                      <span
                        v-else-if="kyc_details.aadhar_status == 'Failed'"
                        class="text-body-2 ml-2 font-weight-bold error--text"
                      >
                        <v-icon class="error--text mr-1">
                          mdi-close-circle-outline</v-icon
                        >Falied
                      </span>
                      <span
                        v-else-if="kyc_details.aadhar_status == 'Pending'"
                        class="text-body-2 ml-2 font-weight-bold secondary_2--text"
                      >
                        <v-icon class="secondary_2--text mr-1">
                          mdi-alert-circle-outline</v-icon
                        >Pending
                      </span>
                      <span v-else> </span>
                    </v-col>
                    <v-col cols="5" class="d-flex justify-end">
                      <v-btn
                        small
                        text
                        class="indigo lighten-5 py-4"
                        :disabled="view_aadhaar_btn"
                        @click="
                          openCompareAadhaarDialog(kyc_details.aadhar_status)
                        "
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                        <!-- <v-icon color="deep-purple darken-4"
                        >mdi-eye-outline</v-icon
                      > -->
                      </v-btn>
                      <!-- <span v-if="kyc_details.aadhar_status == 'Under Review'">
                      <v-btn
                        small
                        outlined
                        color="success"
                        class="font-weight-bold ml-2"
                        @click="updateKycStatus('aadhar_status', 'Success')"
                      >
                        Apporve
                      </v-btn>
                      <v-btn
                        small
                        outlined
                        color="error"
                        class="font-weight-bold ml-2"
                        @click="updateKycStatus('aadhar_status', 'Failed')"
                      >
                        Reject
                      </v-btn>
                    </span> -->
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex align-center justify-space-between px-10 py-0"
                  >
                    <v-col cols="4">
                      <p class="field-title">
                        PAN Card
                      </p>
                      <p
                        v-if="kyc_extra_details.pan_number"
                        class="field-content"
                      >
                        {{ kyc_extra_details.pan_number }}
                      </p>
                      <p v-else class="field-content">
                        -
                      </p>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-center">
                      <span
                        class="text-body-2 ml-2 font-weight-bold success--text"
                        v-if="kyc_details.pan_status == 'Success'"
                      >
                        <v-icon class="success--text mr-1">
                          mdi-check-circle-outline </v-icon
                        >Success
                      </span>
                      <span
                        class="text-body-2 ml-2 font-weight-bold blue--text"
                        v-else-if="kyc_details.pan_status == 'Under Review'"
                      >
                        Under Review
                      </span>
                      <span
                        v-else-if="kyc_details.pan_status == 'Failed'"
                        class="text-body-2 ml-2 font-weight-bold error--text"
                      >
                        <v-icon class="error--text mr-1">
                          mdi-close-circle-outline</v-icon
                        >Falied
                      </span>
                      <span
                        v-else-if="kyc_details.pan_status == 'Pending'"
                        class="text-body-2 ml-2 font-weight-bold secondary_2--text"
                      >
                        <v-icon class="secondary_2--text mr-1">
                          mdi-alert-circle-outline</v-icon
                        >Pending
                      </span>
                      <span v-else> </span>
                    </v-col>
                    <v-col cols="5" class="d-flex justify-end">
                      <v-btn
                        small
                        text
                        @click="openComparePanDialog(kyc_details.pan_status)"
                        :disabled="!kyc_extra_details.pan_url"
                        class="indigo lighten-5 py-4"
                      >
                        <!-- @click="
                          isImageDocument(kyc_extra_details.pan_url)
                            ? previewImage(
                                kyc_extra_details.pan_url,
                                'PAN Card'
                              )
                            : viewDocument(kyc_extra_details.pan_url)
                        " -->
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                      <!-- <span
                        v-if="
                          kyc_extra_details.pan_url &&
                            kyc_details.pan_status != 'Success' &&
                            kyc_details.pan_status != 'Failed'
                        "
                      >
                        <v-btn
                          outlined
                          small
                          color="success"
                          class="font-weight-bold ml-2"
                          @click="updateKycStatus('pan_status', 'Success')"
                        >
                          Apporve
                        </v-btn>
                        <v-btn
                          small
                          outlined
                          color="error"
                          class="font-weight-bold ml-2"
                          @click="updateKycStatus('pan_status', 'Failed')"
                        >
                          Reject
                        </v-btn>
                      </span> -->
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex align-center justify-space-between pb-8 px-10"
                  >
                    <v-col cols="4">
                      <p class="field-title">
                        Selfie
                      </p>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-center">
                      <span
                        class="text-body-2 ml-2 font-weight-bold success--text"
                        v-if="kyc_details.selfie_status == 'Success'"
                      >
                        <v-icon class="success--text mr-1">
                          mdi-check-circle-outline </v-icon
                        >Success
                      </span>
                      <span
                        class="text-body-2 ml-2 font-weight-bold blue--text"
                        v-else-if="kyc_details.selfie_status == 'Under Review'"
                      >
                        Under Review
                      </span>
                      <span
                        v-else-if="kyc_details.selfie_status == 'Failed'"
                        class="text-body-2 ml-2 font-weight-bold error--text"
                      >
                        <v-icon class="error--text mr-1">
                          mdi-close-circle-outline</v-icon
                        >Falied
                      </span>
                      <span
                        v-else-if="kyc_details.selfie_status == 'Pending'"
                        class="text-body-2 ml-2 font-weight-bold secondary_2--text"
                      >
                        <v-icon class="secondary_2--text mr-1">
                          mdi-alert-circle-outline</v-icon
                        >Pending
                      </span>
                      <span v-else> </span>
                    </v-col>
                    <v-col cols="5" class="d-flex justify-end align-center">
                      <!-- <v-btn
                      small
                      text
                      :disabled="!kyc_extra_details.selfie_url"
                      @click="
                        isImageDocument(kyc_extra_details.selfie_url)
                          ? previewImage(kyc_extra_details.selfie_url, 'Selfie')
                          : viewDocument(kyc_extra_details.selfie_url)
                      "
                      class="indigo lighten-5 py-4"
                    >
                      <v-icon color="deep-purple darken-4"
                        >mdi-eye-outline</v-icon
                      >
                    </v-btn> -->
                      <v-btn
                        outlined
                        small
                        color="success"
                        class="mr-3"
                        :loading="kyc_selfie_btn_loader"
                        :disabled="kyc_selfie_btn_loader"
                        v-if="kyc_details.selfie_status == 'Under Review'"
                        @click="bypassSelfie()"
                      >
                        Pass
                      </v-btn>
                      <v-btn
                        small
                        text
                        :disabled="!kyc_extra_details.selfie_url"
                        @click="openCompareSelfieDialog()"
                        class="indigo lighten-5 py-4"
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                      <!-- <span v-if="kyc_details.selfie_status == 'Under Review'">
                      <v-btn
                        small
                        outlined
                        color="success"
                        class="font-weight-bold ml-2"
                        @click="updateKycStatus('selfie_status', 'Success')"
                      >
                        Apporve
                      </v-btn>
                      <v-btn
                        small
                        outlined
                        color="error"
                        class="font-weight-bold ml-2"
                        @click="updateKycStatus('selfie_status', 'Failed')"
                      >
                        Reject
                      </v-btn>
                    </span> -->
                    </v-col>
                  </v-col>
                </v-row>
              </div>
              <div
                v-if="item.title == 'Bank Details' && item.show"
                class="py-5 pb-10 px-6"
              >
                <v-row>
                  <v-col cols="4">
                    <p class="field-title">
                      Bank Name
                    </p>
                    <p v-if="bank_details.bank_name" class="field-content">
                      {{ bank_details.bank_name }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      IFSC Code
                    </p>
                    <p v-if="bank_details.ifsc_code" class="field-content">
                      {{ bank_details.ifsc_code }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <p class="field-title">
                      Account No.
                    </p>
                    <p v-if="bank_details.account_number" class="field-content">
                      {{ bank_details.account_number }}
                    </p>
                    <p v-else class="field-content">
                      -
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  v-for="item in bank_statements"
                  :key="item.id"
                  class="bank-statment-rows"
                >
                  <v-col
                    cols="12"
                    class="d-flex align-center justify-space-between py-0 hover-bg"
                  >
                    <v-col cols="4" class="pl-0">
                      <!-- <p class="field-title">
                        Bank statement
                      </p> -->
                      <p class="field-content">
                        <!-- {{ item.document_type }} -->
                        {{
                          item.document_type
                            .replace(/_/g, " ")
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (s) => s.charAt(0).toUpperCase() + s.substring(1)
                            )
                            .join(" ")
                        }}
                        <span style="color:grey"
                          >({{ getDate(item.created) }})</span
                        >
                      </p>
                      <p
                        v-if="item.document_password"
                        class="primary_2--text font-weight-bold text-caption"
                      >
                        <span>
                          <v-icon small class="success--text">
                            mdi-shield-key
                          </v-icon>
                        </span>
                        {{ item.document_password }}
                      </p>
                      <p class="error--text text-caption">
                        {{ item.rejection_reason }}
                      </p>
                    </v-col>
                    <v-col cols="2">
                      <span
                        class="text-body-2 ml-2 font-weight-bold success--text"
                        v-if="item.document_status == 'approved'"
                      >
                        <v-icon class="success--text mr-1">
                          mdi-check-circle-outline </v-icon
                        >Approved
                      </span>
                      <span
                        class="text-body-2 ml-2 font-weight-bold secondary_2--text"
                        v-else-if="item.document_status == 'requested'"
                      >
                        <v-icon class="secondary_2--text mr-1">
                          mdi-alert-circle-outline </v-icon
                        >Requested
                      </span>
                      <span
                        v-else-if="item.document_status == 'rejected'"
                        class="text-body-2 ml-2 font-weight-bold error--text"
                      >
                        <v-icon class="error--text mr-1">
                          mdi-close-circle-outline</v-icon
                        >Rejected
                      </span>
                      <span v-else></span>
                    </v-col>
                    <v-col cols="5" class="d-flex justify-end">
                      <v-btn
                        small
                        text
                        class="indigo lighten-5 py-4"
                        @click="
                          isImageDocument(item.document)
                            ? previewImage(item.document, item.document_type)
                            : viewDocument(item.document)
                        "
                      >
                        <v-icon color="deep-purple darken-4"
                          >mdi-eye-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </div>
              <div
                v-if="item.title == 'Finvu Section' && item.show"
                class="px-3 pt-2"
              >
                <FinvuSection></FinvuSection>
              </div>
              <div
                v-if="
                  item.title == 'Aadhar Details (for old customer)' && item.show
                "
                class="px-6 pb-6"
              >
                <AdhaaarDetails />
              </div>
              <div
                v-if="item.title == 'Documents (for old customer)' && item.show"
                class="px-6 pb-6"
              >
                <DocumentDetails />
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <!-- request again reason dialog -->
    <RequestAgainReasonDialog
      v-if="document_rejection_reason_data.flag"
      :dialog="document_rejection_reason_data"
      @success="updateDocuments()"
    ></RequestAgainReasonDialog>

    <!-- compare aadhar details dialog -->
    <CompareAadhaarDetailsDialog
      v-if="compare_aadhaar_details_data.flag"
      :dialog="compare_aadhaar_details_data"
      @success="getKycDetails()"
    ></CompareAadhaarDetailsDialog>

    <!-- compare selfie dialog -->
    <CompareSelfieDialog
      v-if="compare_selfie_data.flag"
      :dialog="compare_selfie_data"
      @success="getKycDetails()"
    ></CompareSelfieDialog>

    <!-- edit eligibility check details dialog -->
    <EditEligibilityCheckDialog
      v-if="edit_eligibility_check_data.flag"
      :dialog="edit_eligibility_check_data"
      @success="getCheckEligiblityDetails()"
    ></EditEligibilityCheckDialog>

    <!-- compare aadhar details dialog -->
    <ComparePanDetailsDialog
      v-if="compare_pan_details_data.flag"
      :dialog="compare_pan_details_data"
      @success="getKycDetails()"
    ></ComparePanDetailsDialog>
    <ResetKycConfirmationDialog
      v-if="confirmation_dialog_data.flag"
      :dialog="confirmation_dialog_data"
      @onSuccess="getKycDetails()"
    ></ResetKycConfirmationDialog>
    <AllDocumentModal ref="childComponentRef"></AllDocumentModal>
    <UploadBankStatement
      @onSuccess="getBankDetails"
      ref="uploadBankStatementDialog"
    ></UploadBankStatement>
    <UploadAddressProof
      @onSuccess="getApplicationFormDetails"
      ref="uploadAddressProofDialog"
    ></UploadAddressProof>
  </div>
</template>
<script>
import AllDocumentModal from "../pre-verification/AllDocumentModal.vue";
// import cibil_res from "./cibil-response";
export default {
  data() {
    return {
      loader: false,
      accordionHeaders: [
        {
          id: 1,
          title: "Check Eligibility",
          show: true,
        },
        {
          id: 2,
          title: "Application Form",
          show: true,
        },
        {
          id: 3,
          title: "CIBIL Report",
          show: false,
        },
        {
          id: 4,
          title: "KYC",
          show: true,
        },
        {
          id: 5,
          title: "Bank Details",
          show: true,
        },
        {
          id: 8,
          title: "Finvu Section",
          show: true,
        },
        {
          id: 6,
          title: "Aadhar Details (for old customer)",
          show: true,
        },
        {
          id: 7,
          title: "Documents (for old customer)",
          show: true,
        },
      ],
      check_eligibility_details: [],
      check_eligibility_block_data: [],
      is_check_eligibility_approved: false,
      is_dob_valid: true,
      is_picode_valid: true,
      is_employment_type_valid: true,
      is_salary_type_valid: true,
      is_monthly_salary_valid: true,

      current_address_details: [],
      permanent_address_details: [],
      employment_details: [],
      address_proof: [],
      general_details: [],
      application_form_block_data: [],
      address_proof_type: "",
      is_application_form_approved: false,
      // show_document_rejection_reason_dialog: "",
      document_rejection_reason_data: {
        flag: false,
        document_id: null,
        action: "",
      },
      compare_aadhaar_details_data: {
        flag: false,
        aadhaar_status: "",
        aadhar_pdf_url: "",
        user_fiiled_data: {
          name: "",
          dob: "",
          father_name: "",
          permanent_address: "",
          current_address: "",
        },
        signzy_fetched_data: {
          name: "",
          dob: "",
          address: "",
        },
        pan_fetch_response: "",
      },
      compare_pan_details_data: {
        flag: false,
        aadhaar_status: "",
        user_fiiled_data: {
          name: "",
          dob: "",
          father_name: "",
          pan_number: "",
        },
        pan_url: "",
        pan_ocr_response: "",
        pan_fetch_response: "",
        aadhaar_details: {},
      },
      compare_selfie_data: {
        flag: false,
        selfie_status: "",
        user_uploaded_photo_url: false,
        aadhar_photo_url: false,
        liveness_data: [],
      },
      edit_eligibility_check_data: {
        flag: false,
        first_name: "",
        last_name: "",
        dob: "",
        father_name: "",
      },
      confirmation_dialog_data: {
        flag: false,
        title: "",
        message: "",
      },
      reset_kyc_btn_loader: false,

      kyc_details: [],
      kyc_extra_details: [],
      aadhaar_details: [],
      kyc_block_days_left: "",
      is_kyc_approved: false,
      is_action_requried_kyc: false,
      kyc_selfie_btn_loader: false,

      bank_details: [],
      bank_statements: [],
      bank_details_block_data: [],
      is_bank_details_approved: false,

      are_all_collapsed: false,
    };
  },
  computed: {
    // cibil() {
    //   return cibil_res ? cibil_res : "";
    // },
    view_aadhaar_btn() {
      if (
        this.kyc_details.aadhar_status == "Success" ||
        this.kyc_details.aadhar_status == "Failed"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getCheckEligiblityDetails();
    this.getApplicationFormDetails();
    this.getKycDetails();
    this.getBankDetails();
  },
  components: {
    AllDocumentModal,
    RequestAgainReasonDialog: () =>
      import("@/components/new-customer/dialog/RequestAgainReasonDialog"),
    CompareAadhaarDetailsDialog: () =>
      import("@/components/new-customer/dialog/CompareAadhaarDetailsDialog"),
    CompareSelfieDialog: () =>
      import("@/components/new-customer/dialog/CompareSelfieDialog"),
    DocumentDetails: () => import("@/components/customer/DocumentDetails"),
    AdhaaarDetails: () => import("@/components/customer/AdhaaarDetails"),
    DivLoader: () => import("@/components/ui/DivLoader"),
    EditEligibilityCheckDialog: () =>
      import("@/components/new-customer/dialog/EditEligibilityCheckDialog"),
    ComparePanDetailsDialog: () =>
      import("@/components/new-customer/dialog/ComparePanDetailsDialog"),
    ResetKycConfirmationDialog: () =>
      import("@/components/new-customer/dialog/ResetKycConfirmationDialog"),
    CibilSection: () => import("@/components/new-customer/CibilSection"),
    UploadBankStatement: () =>
      import("@/components/new-customer/dialog/UploadBankStatement"),
    UploadAddressProof: () =>
      import("@/components/new-customer/dialog/UploadAddressProof"),
    FinvuSection: () => import("@/components/new-customer/FinvuSection"),
  },
  methods: {
    checkForValue(value) {
      if (value) {
        if (value.text) return value.text;
      } else {
        return "-";
      }
    },
    checkForValueEmpty(value) {
      if (value) {
        if (value.text) return value.text;
      } else {
        return "";
      }
    },
    sendEventToDocumentsDialog() {
      this.$refs.childComponentRef.viewAllDocuments();
    },
    openUploadBankStatementDialog() {
      let data = {
        account_holder_name: this.check_eligibility_details.name,
        account_number: this.bank_details.account_number,
        bank_name: this.bank_details.bank_name,
        ifsc_code: this.bank_details.ifsc_code,
      };
      this.$refs.uploadBankStatementDialog.openBankStatementDialog(data);
    },
    openUploadAddressProofDialog() {
      this.$refs.uploadAddressProofDialog.openAddressProofDialog();
    },
    // toggle accordion content on click
    toggleAccordion(item) {
      if (item.title == "Check Eligibility") {
        this.accordionHeaders[0].show = !this.accordionHeaders[0].show;
      } else if (item.title == "Application Form") {
        this.accordionHeaders[1].show = !this.accordionHeaders[1].show;
      } else if (item.title == "CIBIL Report") {
        this.accordionHeaders[2].show = !this.accordionHeaders[2].show;
      } else if (item.title == "KYC") {
        this.accordionHeaders[3].show = !this.accordionHeaders[3].show;
      } else if (item.title == "Bank Details") {
        this.accordionHeaders[4].show = !this.accordionHeaders[4].show;
      } else if (item.title == "Finvu Section") {
        this.accordionHeaders[5].show = !this.accordionHeaders[5].show;
      } else if (item.title == "Aadhar Details (for old customer)") {
        this.accordionHeaders[6].show = !this.accordionHeaders[6].show;
      } else if (item.title == "Documents (for old customer)") {
        this.accordionHeaders[7].show = !this.accordionHeaders[7].show;
      }
    },
    toggleAllAccordion() {
      if (this.are_all_collapsed) {
        this.accordionHeaders[0].show = true;
        this.accordionHeaders[1].show = true;
        this.accordionHeaders[2].show = true;
        this.accordionHeaders[3].show = true;
        this.accordionHeaders[4].show = true;
        this.accordionHeaders[5].show = true;
        this.accordionHeaders[6].show = true;
        this.accordionHeaders[7].show = true;
        this.are_all_collapsed = !this.are_all_collapsed;
      } else {
        this.accordionHeaders[0].show = false;
        this.accordionHeaders[1].show = false;
        this.accordionHeaders[2].show = false;
        this.accordionHeaders[3].show = false;
        this.accordionHeaders[4].show = false;
        this.accordionHeaders[5].show = false;
        this.accordionHeaders[6].show = false;
        this.accordionHeaders[7].show = false;
        this.are_all_collapsed = !this.are_all_collapsed;
      }
    },
    viewOnlyEligiblity() {
      if (
        this.accordionHeaders[0].show == true &&
        (this.accordionHeaders[1].show == true ||
          this.accordionHeaders[2].show == true ||
          this.accordionHeaders[3].show == true)
      ) {
        this.accordionHeaders[0].show = true;
        this.accordionHeaders[1].show = false;
        this.accordionHeaders[2].show = false;
        this.accordionHeaders[3].show = false;
        this.accordionHeaders[4].show = false;
        this.accordionHeaders[5].show = false;
        this.accordionHeaders[6].show = false;
        this.accordionHeaders[7].show = false;
      } else {
        this.accordionHeaders[0].show = !this.accordionHeaders[0].show;
        this.accordionHeaders[1].show = false;
        this.accordionHeaders[2].show = false;
        this.accordionHeaders[3].show = false;
        this.accordionHeaders[4].show = false;
        this.accordionHeaders[5].show = false;
        this.accordionHeaders[6].show = false;
        this.accordionHeaders[7].show = false;
      }
    },
    viewOnlyApplication() {
      if (
        this.accordionHeaders[1].show == true &&
        (this.accordionHeaders[0].show == true ||
          this.accordionHeaders[2].show == true ||
          this.accordionHeaders[3].show == true)
      ) {
        this.accordionHeaders[0].show = false;
        this.accordionHeaders[1].show = true;
        this.accordionHeaders[2].show = false;
        this.accordionHeaders[3].show = false;
        this.accordionHeaders[4].show = false;
        this.accordionHeaders[5].show = false;
        this.accordionHeaders[6].show = false;
        this.accordionHeaders[7].show = false;
      } else {
        this.accordionHeaders[0].show = false;
        this.accordionHeaders[1].show = !this.accordionHeaders[1].show;
        this.accordionHeaders[2].show = false;
        this.accordionHeaders[3].show = false;
        this.accordionHeaders[4].show = false;
        this.accordionHeaders[5].show = false;
        this.accordionHeaders[6].show = false;
        this.accordionHeaders[7].show = false;
      }
    },
    viewOnlyKyc() {
      if (
        this.accordionHeaders[2].show == true &&
        (this.accordionHeaders[0].show == true ||
          this.accordionHeaders[1].show == true ||
          this.accordionHeaders[3].show == true)
      ) {
        this.accordionHeaders[0].show = false;
        this.accordionHeaders[1].show = false;
        this.accordionHeaders[2].show = true;
        this.accordionHeaders[3].show = false;
        this.accordionHeaders[4].show = false;
        this.accordionHeaders[5].show = false;
      } else {
        this.accordionHeaders[0].show = false;
        this.accordionHeaders[1].show = false;
        this.accordionHeaders[2].show = !this.accordionHeaders[2].show;
        this.accordionHeaders[3].show = false;
        this.accordionHeaders[4].show = false;
        this.accordionHeaders[5].show = false;
      }
    },
    viewOnlyBank() {
      if (
        this.accordionHeaders[3].show == true &&
        (this.accordionHeaders[0].show == true ||
          this.accordionHeaders[1].show == true ||
          this.accordionHeaders[2].show == true)
      ) {
        this.accordionHeaders[0].show = false;
        this.accordionHeaders[1].show = false;
        this.accordionHeaders[2].show = false;
        this.accordionHeaders[3].show = true;
        this.accordionHeaders[4].show = false;
        this.accordionHeaders[5].show = false;
      } else {
        this.accordionHeaders[0].show = false;
        this.accordionHeaders[1].show = false;
        this.accordionHeaders[2].show = false;
        this.accordionHeaders[3].show = !this.accordionHeaders[3].show;
        this.accordionHeaders[4].show = false;
        this.accordionHeaders[5].show = false;
      }
    },
    // view document
    viewDocument(url) {
      window.open(url, "_blank");
    },

    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(url) {
      let ext = url.split(".");
      ext = ext[ext.length - 1];
      return ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "webp";
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(url, document_name) {
      console.log("image preview", url);
      let image_url = url;

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_name),
        },
      });
      window.open(route.href, "_blank");
    },

    // open request again reason dialog
    openRequestAgainReasonDialog(document_id, action) {
      this.document_rejection_reason_data.flag = true;
      this.document_rejection_reason_data.document_id = document_id;
      this.document_rejection_reason_data.action = action;
    },
    // open compare aadhaar details dialog
    openCompareAadhaarDialog(status) {
      this.compare_aadhaar_details_data.flag = true;
      this.compare_aadhaar_details_data.aadhaar_status = status;
      this.compare_aadhaar_details_data.user_fiiled_data.name = this.check_eligibility_details.name.toUpperCase();
      this.compare_aadhaar_details_data.user_fiiled_data.dob = this.check_eligibility_details.date_of_birth;
      this.compare_aadhaar_details_data.user_fiiled_data.father_name = this.check_eligibility_details.father_name.toUpperCase();
      this.compare_aadhaar_details_data.user_fiiled_data.current_address = `
          ${this.current_address_details.home_number}
          ${this.current_address_details.area}
          ${this.current_address_details.city}
          ${this.current_address_details.state}
          ${this.current_address_details.pin_code}
      `.toUpperCase();
      this.compare_aadhaar_details_data.user_fiiled_data.permanent_address = `
          ${this.permanent_address_details.home_number}
          ${this.permanent_address_details.area}
          ${this.permanent_address_details.city}
          ${this.permanent_address_details.state}
          ${this.permanent_address_details.pin_code}
      `.toUpperCase();
      this.compare_aadhaar_details_data.signzy_fetched_data.name = this.aadhaar_details.name;
      this.compare_aadhaar_details_data.aadhar_pdf_url = this.aadhaar_details.aadhar_pdf_url;
      this.compare_aadhaar_details_data.signzy_fetched_data.dob = this.aadhaar_details.date_of_birth;
      this.compare_aadhaar_details_data.signzy_fetched_data.address = this.aadhaar_details.address;
    },
    // open compare pan details dialog
    openComparePanDialog(status) {
      // if (this.pan_details) {
      this.compare_pan_details_data.flag = true;
      this.compare_pan_details_data.aadhaar_status = status;
      this.compare_pan_details_data.user_fiiled_data.name = this.check_eligibility_details.name.toUpperCase();
      this.compare_pan_details_data.user_fiiled_data.dob = this.check_eligibility_details.date_of_birth;
      this.compare_pan_details_data.user_fiiled_data.father_name = this.check_eligibility_details.father_name.toUpperCase();
      this.compare_pan_details_data.user_fiiled_data.pan_number = this.check_eligibility_details.pan_number;
      this.compare_pan_details_data.pan_url = this.kyc_extra_details.pan_url;
      this.compare_pan_details_data.aadhaar_details = this.aadhaar_details;
      // } else {
      //   this.previewImage(this.kyc_extra_details.pan_url, "PAN");
      // }
    },
    // open compare selfie dialog
    openCompareSelfieDialog() {
      this.compare_selfie_data.flag = true;
      this.compare_selfie_data.selfie_status = this.kyc_details.selfie_status;
      this.compare_selfie_data.user_uploaded_photo_url = this.kyc_extra_details.selfie_url;
      this.compare_selfie_data.aadhar_photo_url = this.aadhaar_details.aadhar_photo_url;
      this.compare_selfie_data.liveness_data = this.kyc_extra_details;
    },
    // open edit eligibility check details dialog
    openEditEligibilityCheck() {
      this.edit_eligibility_check_data.flag = true;
      this.edit_eligibility_check_data.first_name = this.check_eligibility_details.first_name;
      this.edit_eligibility_check_data.last_name = this.check_eligibility_details.last_name;
      this.edit_eligibility_check_data.dob = this.check_eligibility_details.date_of_birth;
      this.edit_eligibility_check_data.father_name = this.check_eligibility_details.father_name;
    },
    // open confirmation dialog
    openConfirmationDialog(title, message) {
      this.confirmation_dialog_data.flag = true;
      this.confirmation_dialog_data.title = title;
      this.confirmation_dialog_data.message = message;
    },
    // recall GET functions on success edit
    updateDocuments() {
      this.getBankDetails();
      this.getApplicationFormDetails();
    },
    // fetch eligibility check details from server
    getCheckEligiblityDetails() {
      const self = this;

      self.loader = true;

      let params = {
        customer_id: this.decrypt(this.$route.params.id),
      };

      const successHandler = (response) => {
        if (response.data.success) {
          self.check_eligibility_details = response.data.eligibility_check;
          self.is_check_eligibility_approved = response.data.is_user_eligible;
          self.check_eligibility_block_data = response.data.blocked_data;
          if (self.check_eligibility_block_data) {
            for (
              let i = 0;
              i < self.check_eligibility_block_data.blocked_reason.length;
              i++
            ) {
              // console.log(
              //   "reson",
              //   self.check_eligibility_block_data.blocked_reason[i]
              //     .rejection_reason
              // );
              if (
                self.check_eligibility_block_data.blocked_reason[i]
                  .rejection_reason == "Employment Type"
              ) {
                self.is_employment_type_valid = false;
              } else if (
                self.check_eligibility_block_data.blocked_reason[i]
                  .rejection_reason == "Salary Type"
              ) {
                self.is_salary_type_valid = false;
              } else if (
                self.check_eligibility_block_data.blocked_reason[i]
                  .rejection_reason == "Net Monthly Salary"
              ) {
                self.is_monthly_salary_valid = false;
              } else if (
                self.check_eligibility_block_data.blocked_reason[i]
                  .rejection_reason == "Pincode"
              ) {
                self.is_picode_valid = false;
              } else if (
                self.check_eligibility_block_data.blocked_reason[i]
                  .rejection_reason == "Date of Birth"
              ) {
                self.is_dob_valid = false;
              }
            }
          }
        }
      };
      const finallyHandler = () => {
        self.loader = false;
      };

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_CHECK_ELIGIBLITY,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },

    // fetch application form details from server
    getApplicationFormDetails() {
      const self = this;
      let params = {
        customer_id: this.decrypt(this.$route.params.id),
      };

      const successHandler = (response) => {
        if (response.data.success) {
          // console.log(response);
          // self.address_details = response.data.application_form.address_details;
          self.employment_details =
            response.data.application_form.employment_details;
          self.general_details = response.data.application_form.general_info;
          self.current_address_details =
            response.data.application_form.address_details.current_address;
          self.address_proof = response.data.application_form.address_proof;
          // console.log("address proof", self.address_proof);
          if (
            response.data.application_form.address_details.current_address
              .same_as_current_address == true
          ) {
            self.permanent_address_details =
              response.data.application_form.address_details.current_address;
          } else {
            self.permanent_address_details =
              response.data.application_form.address_details.permanent_address;
          }

          if (
            !this.current_address_details
              .aadhaar_address_same_as_current_address &&
            this.current_address_details.residential_type != "Own"
          ) {
            let length = this.address_proof.length;

            let count = 0;
            for (let value of this.address_proof) {
              if (value.document_status == "approved") {
                count = count + 1;
                if (count == length) {
                  self.is_application_form_approved = true;
                }
              } else {
                self.is_application_form_approved = false;
              }
            }
            count = 1;
            self.address_proof_type = this.address_proof[0].document_type
              .replace(/_/g, " ")
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
          } else {
            self.is_application_form_approved = true;
          }
        }
      };
      const finallyHandler = () => {};

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_APPLICATION_FORM,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },

    // fetch kyc details from server
    getKycDetails() {
      const self = this;
      let params = {
        customer_id: this.decrypt(this.$route.params.id),
      };

      const successHandler = (response) => {
        if (response.data.success) {
          // console.log("kyc details",response);
          self.kyc_details = response.data.kyc_status;
          self.kyc_extra_details = response.data.kyc_extra_info;
          self.aadhaar_details = response.data.aadhar_data;
          if (response.data.kyc_extra_info.pan_ocr_response) {
            self.compare_pan_details_data.pan_ocr_response = JSON.parse(
              response.data.kyc_extra_info.pan_ocr_response
            );
          }
          if (response.data.kyc_extra_info.pan_fetch_response) {
            self.compare_pan_details_data.pan_fetch_response = JSON.parse(
              response.data.kyc_extra_info.pan_fetch_response
            );
            self.compare_aadhaar_details_data.pan_fetch_response = JSON.parse(
              response.data.kyc_extra_info.pan_fetch_response
            );
          }
          // else if(response.data.kyc_extra_info)
          if (
            response.data.kyc_status.aadhar_status == "Success" &&
            response.data.kyc_status.pan_status == "Success" &&
            response.data.kyc_status.selfie_status == "Success"
          ) {
            self.is_kyc_approved = true;
          } else {
            self.is_kyc_approved = false;
            self.kyc_block_days_left = response.data.block_days_left;
            // console.log("block_days_left", self.kyc_block_days_left);
          }
          if (
            response.data.kyc_status.aadhar_status == "Under Review" &&
            response.data.kyc_status.pan_status == "Under Review" &&
            response.data.kyc_status.selfie_status == "Under Review"
          ) {
            self.is_action_requried_kyc = true;
          } else {
            self.is_action_requried_kyc = false;
            self.kyc_block_days_left = response.data.block_days_left;
            // console.log("block_days_left", self.kyc_block_days_left);
          }
        }
      };
      const finallyHandler = () => {};

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_KYC_DETAILS,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },

    // fetch bank details details from server
    getBankDetails() {
      const self = this;
      let params = {
        customer_id: this.decrypt(this.$route.params.id),
      };

      const successHandler = (response) => {
        if (response.data.success) {
          // console.log("bank details", response);
          self.bank_details = response.data.bank_details;
          self.bank_statements = response.data.bank_statement;
          if (self.bank_statements) {
            let length = this.bank_statements.length;

            let count = 0;
            for (let value of this.bank_statements) {
              if (value.document_status == "approved") {
                count = count + 1;
                if (count == length) {
                  self.is_bank_details_approved = true;
                }
              } else {
                self.is_bank_details_approved = false;
              }
            }
            count = 1;
          } else {
            self.is_bank_details_approved = false;
          }
        }
      };
      const finallyHandler = () => {};

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_BANK_DETAILS,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    // update kyc document status
    updateKycStatus(document_type, action) {
      const self = this;
      // console.log("type", document_type, "action", action);

      let form = new FormData();
      form.append("customer_id", self.decrypt(self.$route.params.id));
      form.append("kyc_field", document_type);
      form.append("kyc_status", action);

      const successHandler = (response) => {
        if (response.data.success) {
          // console.log("kyc update res",response);
          self.getKycDetails();
        }
      };
      const finallyHandler = () => {};
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_UPDATE_KYC_STATUS,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    // update address proof and bank statement document status
    updateDocumentStatus(document_id, action) {
      const self = this;

      // console.log("updateDocumentStatus", document_id, action);

      let form = new FormData();
      form.append("document_id", document_id);
      form.append("document_status", action);
      form.append("rejection_reason", "");

      const successHandler = (response) => {
        if (response.data.success) {
          // console.log("kyc update res",response);
          self.getBankDetails();
          self.getApplicationFormDetails();
        }
      };
      const finallyHandler = () => {};
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    bypassSelfie() {
      const self = this;

      self.kyc_selfie_btn_loader = true;

      let form = new FormData();
      form.append("customer_id", self.decrypt(self.$route.params.id));
      form.append("selfie_status", "Success");

      const successHandler = (response) => {
        if (response.data.success) {
          this.getKycDetails();
        }
      };
      const finallyHandler = () => {
        self.kyc_selfie_btn_loader = false;
      };
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_APPROVE_KYC_SELFIE,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getDate(item) {
      let dateObj = new Date(item);
      let year = dateObj.getFullYear();
      let Month = dateObj.getMonth() + 1;
      let date = dateObj.getDate();
      let formatted_date = date + "-" + Month + "-" + year;
      return formatted_date;
    },
  },
};
</script>
